import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController, ModalController, ActionSheetController } from '@ionic/angular';

import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component'
import { N7ModalConfirmComponent } from '../n7-modal-confirm/n7-modal-confirm.component'

// import * as moment from 'moment';
import { formatDate, formatDistanceToNow, formatRelative } from 'date-fns';

const defaultItem = {
  name: 'Document',
  link: '',
  upload_date: ''
};

@Component({
  selector: 'n7-report-item-preview',
  templateUrl: './n7-report-item-preview.component.html',
  styleUrls: ['./n7-report-item-preview.component.scss'],
})
export class N7ReportItemPreviewComponent implements OnChanges {
  public item: any;
  public itemType: string;
  public itemStyle: string = 'default';
  public itemLoading: boolean = false;
  public itemHideHeader: boolean = false;
  public itemHideUpdates: boolean = false;
  public itemHideProgress: boolean = false;
  public itemHideActions: boolean = false;
  public itemShowCheckbox: boolean = false;
  public itemShowRadio: boolean = false;
  public itemSelected: boolean = false;
  public itemCanBeDeleted: boolean = true;

  @Input() set reportItem(val: any) {
    this.item = (val !== undefined && val !== null) ? val : defaultItem;
  }

  @Input() set style(val: string) {
    this.itemStyle = (val !== undefined && val !== null) ? val : 'default';
  }

  @Input() set loading(val: boolean) {
    this.itemLoading = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hideHeader(val: boolean) {
    this.itemHideHeader = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hideUpdates(val: boolean) {
    this.itemHideUpdates = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hideProgress(val: boolean) {
    this.itemHideProgress = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set hideActions(val: boolean) {
    this.itemHideActions = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showCheckbox(val: boolean) {
    this.itemShowCheckbox = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showRadio(val: boolean) {
    this.itemShowRadio = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set selected(val: boolean) {
    this.itemSelected = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canBeDeleted(val: boolean) {
    this.itemCanBeDeleted = (val !== undefined && val !== null) ? val : true;
  }

  @Output() itemView = new EventEmitter()

  @Output() itemEdit = new EventEmitter()

  @Output() itemOpen = new EventEmitter()

  @Output() itemDelete = new EventEmitter()

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    public actionSheetController: ActionSheetController
  ) {
    this.itemType = 'doc';
  }

  ngOnChanges() {
    if (this.item && this.item.report_type) {
      this.item.report_lastupdate_text = this.dateToFromNowDaily(this.item.report_update_date);

      // Author Initials
      this.item.author.initials = this.item.author.name.charAt(0).toUpperCase() + this.item.author.surname.charAt(0).toUpperCase();

      if (this.item.data_attributes && this.item.data_attributes.last_editor) {
        // Last editor Initials
        this.item.data_attributes.last_editor.initials = this.item.data_attributes.last_editor.name.charAt(0).toUpperCase() + this.item.data_attributes.last_editor.surname.charAt(0).toUpperCase();
      }

      switch (this.item.report_type) {
        case 'application_log':
          this.itemType = 'log';
          this.item.data_attributes.text_zones = '';
          if (this.item.data_attributes.zones) {
            this.item.data_attributes.text_zones = this.item.data_attributes.zones.map(e => e.description).join(', ');
          }
          if (this.item.data_attributes.started_sections) {
            this.item.data_attributes.sectionsCount = 4;
            this.item.data_attributes.startedSectionsCount = this.item.data_attributes.started_sections.length;
          }

          break;

        case 'environmental_log_data':
          this.itemType = 'log';
          break;

        case 'environmental_log_document':
          this.itemType = 'doc';
          break;

        case 'list_of_points_document':
          this.itemType = 'doc';
          break;

        case 'list_of_points_overview_document':
          this.itemType = 'doc';
          break;

        case 'application_log_report':
          this.itemType = 'doc';
          if (this.item && this.item.data_attributes) {
            this.item.data_attributes.text_zones = '';
          }
          // this.item.data_attributes.text_zones = '';
          // if (this.item.data_attributes.zones) {
          //   this.item.data_attributes.text_zones = this.item.data_attributes.zones.map(e => e.description).join(', ');
          // }
          break;

        case 'surface_availabilities_report':
          this.itemType = 'doc';
          break;

        default:
          this.itemType = 'doc';
          break;
      }
    }
  }

  defaultAction() {
    switch (this.item.report_type) {
      case 'application_log':
        this.action('edit');
        break;

      case 'list_of_points_document':
      case 'list_of_points_overview_document':
      case 'environmental_log_document':
      case 'application_log_report':
      case 'surface_availabilities_report':
        this.action('open');
        break;

      default:
        console.log('defaultAction', this.itemType, this.item);
    }
  }

  action(type: string) {
    // console.log('action', type);
    switch (type) {
      case 'view':
        this.itemView.emit(this.item.id);
        break;

      case 'edit':
        this.itemEdit.emit(this.item.id);
        break;

      case 'open':
        this.itemOpen.emit(this.item.id);
        break;

      case 'delete':
        if (this.itemCanBeDeleted) {
          this.presentItemRemoveConfirmModal(this.item.id, {});
          // this.itemDelete.emit(this.item.id);
        }
        break;
    }
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Actions',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: '',
        handler: () => {
          this.action('delete');
        }
      }]
    });
    await actionSheet.present();
  }

  async presentContextualMenu(ev: any) {
    const nameFormItem = {
      type: 'button',
      title: 'Delete',
      theme: 'delete'
    };
    const popover = await this.popoverController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: 'Options',
        formItems: [nameFormItem],
        type: 'popover',
        dismissLabel: ''
      },
      cssClass: 'modal-form',
      event: ev,
      showBackdrop: false,
      translucent: false
    });

    popover.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && (null != data.data.buttonClicked)) {
          switch (data.data.buttonClicked) {
            case 0:
              this.action('delete');
              break;

            default:
              console.log(data.data.buttonClicked);
          }
        }
      }

    }).catch((error) => {
      console.log('Error', error);
    });

    return await popover.present();
  }

  async presentItemRemoveConfirmModal(itemId: string, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Delete document',
        message: 'Do you want delete this document?',
        dismissLabel: 'Cancel',
        confirmLabel: 'Yes, delete'
      },
      cssClass: 'modal-confirm'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          this.itemDelete.emit(this.item.id);
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  simpleCounter(n: number): any[] {
    return Array(n);
  }

  dateToFromNowDaily(customDate) {
    // get from-now for this date
    // var fromNow = moment(customDate).fromNow();
    var fromNow = formatDistanceToNow(customDate);

    const result = formatRelative(customDate, new Date());
    // ensure the date is displayed with today and yesterday
    return result;
    // return moment(customDate).calendar(null, {
    //   // when the date is closer, specify custom values
    //   lastWeek: '[Last] dddd [at] HH:mm',
    //   lastDay: '[Yesterday] [at] HH:mm',
    //   sameDay: '[Today] [at] HH:mm',
    //   nextDay: '[Tomorrow] [at] HH:mm',
    //   nextWeek: 'dddd [at] HH:mm',
    //   // when the date is further away, use from-now functionality             
    //   sameElse: function () {
    //     return "[" + fromNow + "]";
    //   }
    // });
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams, IonSlides, Platform } from '@ionic/angular';
import { N7CanvasComponent } from '../n7-canvas/n7-canvas.component';

@Component({
  selector: 'n7-modal-draw',
  templateUrl: './n7-modal-draw.component.html',
  styleUrls: ['./n7-modal-draw.component.scss'],
})
export class N7ModalDrawComponent implements OnInit {
  @ViewChild('slides') slides: IonSlides;
  @ViewChild('canvasWrapper', { read: ElementRef }) private canvasWrapper: ElementRef;
  @ViewChild(N7CanvasComponent) n7Canvas: N7CanvasComponent;


  public itemType: string;
  public item: any;
  // public index: number;

  public platformWidth: number;
  public platformHeight: number;

  public image: string;
  public toolType: string = 'pencil';
  public lineColor: string = '#3880ff';
  public canvasHasUndo: boolean;
  public canvasDpr: number = 1;
  public canvasWidth: number;
  public canvasHeight: number;

  public imageWidth: number;
  public imageHeight: number;

  public fabColor = 'primary';
  public fabIcon = 'settings';


  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    platform: Platform
  ) {
    this.itemType = this.navParams.get('itemType');
    this.item = this.navParams.get('items');
    platform.ready().then((readySource) => {
      this.platformWidth = platform.width();
      this.platformHeight = platform.height();
    });
  }

  async ngOnInit() {
    this.image = '';
    if (this.item && ('base64' === this.itemType)) {
      this.image = this.item;
    }
    if (this.item && ('document' === this.itemType)) {
      this.image = 'data:image/png;base64,' + this.item.attributes.base64;
    }
    let self = this;
    await this.getImageMeta(this.image, function (width, height) {
      // console.log(width + 'px ' + height + 'px');
      self.imageWidth = width;
      self.imageHeight = height;
    });

    this.canvasHeight = (this.platformHeight) ? this.platformHeight : this.canvasWrapper.nativeElement.offsetHeight; // - this.safeAreaInsetBottom;
    this.canvasWidth = (this.platformWidth) ? this.platformWidth : this.canvasWrapper.nativeElement.offsetWidth;

    const imageRatio = this.imageWidth / this.imageHeight;
    const canvasRatio = this.canvasWidth / this.canvasHeight;

    if (imageRatio >= canvasRatio) {
      this.canvasDpr = this.imageWidth / this.canvasWidth;
      this.canvasHeight = this.canvasWidth / imageRatio;
    }
    else {
      this.canvasDpr = this.imageHeight / this.canvasHeight;
      this.canvasWidth = this.canvasHeight / imageRatio;
    }
    this.canvasDpr = 1;
    setTimeout(() => {
      this.n7Canvas.initCanvas();
      this.n7Canvas.restart();
    }, 100);
  }

  ionViewDidEnter() {
  }

  dismiss() {
    this.modalController.dismiss(
      {
        confirmed: false
      }
    );
  }

  confirm() {
    this.modalController.dismiss(
      {
        confirmed: true,
        image: this.n7Canvas.getCurrent()
      }
    );
  }

  async getImageMeta(url, callback) {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image;
        img.src = url;
        img.onload = () => {
          callback(img.width, img.height);
          resolve(true);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  canvasUndo() {
    // this.n7Canvas.undo();
    this.n7Canvas.restart();
  }

  onHasUndo(length) {
    this.canvasHasUndo = (length);
  }

  skipDraw() {
  }

  lineColorChange($event) {
    this.lineColor = $event.detail.value;
    switch (this.lineColor) {
      case '#f04141':
        this.fabColor = 'danger';
        break;

      case '#10dc60':
        this.fabColor = 'success';
        break;

      case '#f4f5f8':
        this.fabColor = 'light';
        break;

      case '#222428':
        this.fabColor = 'dark';
        break;

      case '#3880ff':
      default:
        this.fabColor = 'primary';
        break;
    }
  }

  toolTypeChange($event) {
    this.toolType = $event.detail.value;
    switch (this.toolType) {
      case 'pencil':
        this.fabIcon = 'create';
        break;

      case 'rect':
        this.fabIcon = 'square-outline';
        break;

      case 'circle':
        this.fabIcon = 'radio-button-off';
        break;

      default:
        this.fabIcon = 'settings';
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { N7ModalConfirmComponent } from 'src/app/components/n7-modal-confirm/n7-modal-confirm.component';
import { UserService } from '../services/user.service';
import { StorageService } from '../services/storage/storage.service';
// import { Storage } from '@ionic/storage';

const SHOW_SAVED = 'show_saved';
const ICON = '<img src="assets/icon/share-outline.svg" class="icon">';
const TITLE = 'Per aggiungere questa web app alla schermata iniziale:';
const MESSAGE = '1. Premi ' + ICON + '<br/> 2. Aggiungi alla home';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public api: UserService,
    public router: Router,
    public platform: Platform,
    public storage: StorageService,
    public modalController: ModalController
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.platform.ready().then(() => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      if (this.platform.is('ios') && !isStandalone) {
        this.storage.get(SHOW_SAVED).then(async val => {
          if (!val) {
            const itemOverviewHintModal = await this.modalController.create({
              component: N7ModalConfirmComponent,
              componentProps: {
                titleLabel: TITLE,
                message: MESSAGE,
                dismissLabel: 'Cancel',
                confirmLabel: ''
              },
              cssClass: 'modal-confirm'
            });

            this.storage.set(SHOW_SAVED, true);
            await itemOverviewHintModal.present();
          }
        });
      }
    });

    const token = next.queryParams['token'];
    if (token) {
      this.api.setToken(token);
      return true;
    }
    else {
      if (!this.api.userAuthenticated()) {
        return this.api.autoLogin().then(async res => {
          if (res != null && res == true) {
            return true;
          }
          else {
            this.api.logout();
            this.router.navigate(['auth']);
            return false;
          }
        });
      }
      else {
        return true;
      }
    }
  }
}